// ** Logo
import logo from '@src/assets/images/logo/leadmintLogo.gif'

const SpinnerComponent = () => {
  return (
    <div className='d-flex justify-content-center align-item-center' >
      <div className='loading'>
      <img width={350} height={350} className='fallback-logo' src={logo} alt='logo' />
        {/* <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div> */}
      </div>
    </div>
  )
}

export default SpinnerComponent
